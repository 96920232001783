import { computedFrom, inject } from 'aurelia-framework';
import { AppContainer }         from 'resources/services/app-container';
import { DangerAlertMessage }   from 'resources/services/danger-alert-message';
import { SuccessAlertMessage }  from 'resources/services/success-alert-message';
import { WarningAlertMessage }  from 'resources/services/warning-alert-message';

@inject(AppContainer)
export class BaseViewModel {

    observers      = [];
    eventListeners = [];

    /**
     * Constructor
     *
     * @param appContainer
     */
    constructor(appContainer) {
        this.appContainer = appContainer;
    }

    /**
     * Handles activate event
     */
    activate(navigationInstruction, routeConfig) {
        this.navigationInstruction = navigationInstruction;
    }

    /**
     * Handles attached event
     */
    attached() {
        this.subscribeObservers();
        this.subscribeEventListeners();

        this.headerSubtitle = this.model ? this.model.name : '';
    }

    /**
     * Handles detached event
     */
    detached() {
        this.disposeObservers();
        this.disposeEventListeners();

        //this.appContainer.theme.fixDanglingTooltips();
    }

    /**
     * Subscribers observers
     */
    subscribeObservers() {
        // nothing to do here, override in child class
    }

    /**
     * Subscribers observers
     */
    subscribeEventListeners() {
        // nothing to do here, override in child class
    }

    /**
     * Disposes observers
     */
    disposeObservers() {
        this.observers.forEach((observer) => observer.dispose());

        this.observers.splice(0, this.observers.length);
    }

    /**
     * Disposes observers
     */
    disposeEventListeners() {
        this.eventListeners.forEach((eventListener) => eventListener.dispose());

        this.eventListeners.splice(0, this.eventListeners.length);
    }

    /**
     * Checks if the given authorization logic checks out.
     */
    authorize(hasPermission) {
        if (!hasPermission) {
            let notice = this.appContainer.i18n.tr('text.error-message.forbidden');

            this.appContainer.notifier.dangerNotice(notice);
        }

        return hasPermission;
    }

    /**
     * Check if the user has a permission or permissions.
     */
    can(permission, all = false) {
        let hasPermission = this.appContainer.authenticatedUser.can(permission, all);

        if (!hasPermission) {
            let notice = this.appContainer.i18n.tr('text.error-message.forbidden');

            this.appContainer.notifier.dangerNotice(notice);
        }

        return hasPermission;
    }

    /**
     * Check if the user has a role or roles.
     */
    is(role, all = false) {
        let hasRole = this.appContainer.authenticatedUser.is(role, all);

        if (!hasRole) {
            let notice = this.appContainer.i18n.tr('text.error-message.forbidden');

            this.appContainer.notifier.dangerNotice(notice);
        }

        return hasRole;
    }

    /**
     * Fetches data from server
     *
     * @param params
     *
     * @returns {Promise<Array>}
     */
    fetchData(params = null) {
        return Promise.resolve([]);
    }

    /**
     * Blocks UI
     *
     * @param target
     */
    blockUI(target = $('.content-wrapper')) {
        //this.appContainer.theme.blockUI(target);
    }

    /**
     * Blocks UI
     *
     * @param target
     */
    unblockUI(target = $('.content-wrapper')) {
        //this.appContainer.theme.unblockUI(target);
    }

    /**
     * Redirects to a given route after a given delay
     *
     * @param route
     * @param delay
     */
    redirectToRoute(route, delay = 0) {
        let routeName = route;
        let params    = {};

        if (route !== null && route instanceof Object) {
            routeName = route.name;
            params    = route.params;
        }

        setTimeout(() => this.appContainer.router.navigateToRoute(routeName, params), delay);
    }

    /**
     * Returns a new alert message
     *
     * @param status
     * @param message
     * @param details
     * @param scrollTop
     * @param css
     * @param icon
     *
     * @returns {*}
     */
    alertMessage(status, message, details, scrollTop, css, icon) {
        return status === true
            ? this.successAlertMessage(message, details, scrollTop, css, icon)
            : this.dangerAlertMessage(message, details, scrollTop, css, icon);
    }

    /**
     * Returns a new success alert message
     *
     * @param message
     * @param details
     * @param scrollTop
     * @param css
     * @param icon
     *
     * @returns {SuccessAlertMessage}
     */
    successAlertMessage(message, details, scrollTop, css, icon) {
        return new SuccessAlertMessage(
            message,
            details,
            scrollTop,
            css,
            icon,
        );
    }

    /**
     * Returns a new warning alert message
     *
     * @param message
     * @param details
     * @param scrollTop
     * @param css
     * @param icon
     *
     * @returns {WarningAlertMessage}
     */
    warningAlertMessage(message, details, scrollTop, css, icon) {
        return new WarningAlertMessage(
            message,
            details,
            scrollTop,
            css,
            icon,
        );
    }

    /**
     * Returns a new danger alert message
     *
     * @param message
     * @param details
     * @param scrollTop
     * @param css
     * @param icon
     *
     * @returns {DangerAlertMessage}
     */
    dangerAlertMessage(message, details, scrollTop, css, icon) {
        return new DangerAlertMessage(
            message,
            details,
            scrollTop,
            css,
            icon,
        );
    }

    @computedFrom('alert')
    get alertVisibility() {
        return this.alert ? '' : 'display-hide';
    }

    /**
     * Returns dropdown selected text
     *
     * @param field
     *
     * @returns {*|jQuery}
     */
    dropdownSelectedText(field) {
        return $('#' + field + ' option:selected').text();
    }

    /**
     * Redirects to select lot intervention page if the user is not using identifier or has not selected a lot of intervention
     *
     * @return {boolean}
     */
    redirectToSelectLotIntervention(backRoute = null, params = null) {
        if (this.appContainer.authenticatedUser.shouldSelectLotIntervention()) {
            this.redirectToRoute({
                name:   this.appContainer.authenticatedUser.lotInterventionSelectionRoute,
                params: {
                    backRoute: backRoute,
                    ...(params || {}),
                },
            });

            return false;
        }

        return true;
    }

}
